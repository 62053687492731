import React from 'react';
import { NextPage, NextPageContext } from 'next';
import Link from 'next/link';

interface Props {
  statusCode?: number;
}

const ErrorPage: NextPage<Props> = ({ statusCode }) => {
  return (
    <div className="flex h-screen w-screen items-center justify-center px-4">
      <div className="container max-w-screen-md text-center">
        <div data-text={statusCode} className="p-xl-10 p-5 text-center">
          <div className="glitch text-8xl font-semibold" data-text={statusCode}>
            {statusCode}
          </div>
          <p className="mb-5 block">
            {statusCode
              ? `An error ${statusCode} occurred on server`
              : 'An error occurred on client'}
          </p>
        </div>
        <Link href="/" className="btn btn-primary mt-10">
          <span className="text-style">Back to Home</span>
        </Link>
      </div>
    </div>
  );
};

ErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  let statusCode;

  if (res) {
    statusCode = res.statusCode;
  } else if (err) {
    statusCode = err.statusCode;
  } else {
    statusCode = 404;
  }
  return { statusCode };
};

export default ErrorPage;
